.bingo {
    margin-top: 1.5rem;
    overflow-y: scroll;
}

.bingo-row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.bingo-col1, .bingo-col2, .bingo-col3, .bingo-col4, .bingo-col5 {
    background: white;
    border: 2px solid black;
    max-width: 6rem;
    height: 6rem;
    width: 20vw;
    height: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bingo__menu {
    background: white;
    display: flex;
    flex-direction: row;
    border-radius: 1rem 1rem 0 0;
    border: 2px ridge darkblue;
    border-bottom: 2px solid black;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.bingo__menu div {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.bingo__modal-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        rgba(0, 0, 0, 0.3), 
        rgba(0, 0, 0, 0.3)
    );
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0;
    align-items: center;
}

.bingo__modal {
    max-width: 48rem;
    min-width: 16rem;
    background: white;
    -webkit-filter: brightness(100%);
    padding: 2rem;
    border-radius: 2rem;
    border: 4px ridge darkblue;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow-y: scroll;
    z-index: 4000;
    height: 25%;
}

.bingo__modal--x {
    position: absolute;
    right: 1rem;
    top: 1rem;
    text-decoration: none;
    background: none;
    border-radius: 50%;
    border: 1px solid black;
    padding: 0 0.25rem;
    cursor: pointer;
}

.bingo__modal h2 {
    margin-top: 0;
}

.bingo__modal--field {
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & span.bingo__span {
        margin-bottom: 0.5rem;
        margin-left: 0.25rem;
    }
}

html, body {
  height: 100%;
  width: 100%;
  font-family: var(--ubuntu), sans-serif; 
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

.main {
  display: flex;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.bg {
  position: fixed;
  background: #060805 url('./assets/canyon.jpg') no-repeat center center fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.bg.bg--fire {
  background: #060805 url('./assets/beach-bonfire.jpg') no-repeat center center fixed;
}

.main .page {
  width: 100%;
  max-width: 64rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
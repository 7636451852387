.bible {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
}

.bible__modal--overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}

.bible__modal {
  width: 25rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 20rem;
}

.bible__modal--field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.bible__modal h2 {
  margin-bottom: 16px;
}

.bible__sidebar {
  min-width: 20rem;
  background: linear-gradient(to top left, #f2f2f2 0%, #a3a3a3 100%);
  display: inline-flex;
  flex-direction: column;
  overflow-y: scroll ;
}

.bible__sidebar h2 {
  margin: 8px 8px 12px;
}

.bible__sidebar p {
  margin-top: 8px;
}

.bible__accordion {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
}

.bible__accordion:nth-of-type(1) {
  border-top: 1px solid black;
}

.bible__accordion__btn {
  padding: 8px;
  width: 100%;
  background: transparent;
  border: none;
}

.bible__accordion__btn:hover {
  cursor: pointer;
}

.bible__accordion a {
  text-decoration: none;
  color: black;
}

.book-span {
  margin-bottom: 8px;
}

.bible__content {
  overflow-y: scroll;
  white-space: pre-wrap;
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
}

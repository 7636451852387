.darts {
    background: url('../../assets/corkboard.jpg') center center;
    width: 100%;
    max-width: 50rem;
    max-height: 50rem;
    height: 100%;
    min-width: 16rem;
    min-height: 16rem;
    display: flex;
    flex-direction: column;
    border: 2px ridge black;
}

.darts__menu {
    background: white;
    border: 2px ridge black;
    border-bottom: none;
    margin: 1rem 0 0 0;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    max-width: 49rem;
    min-width: 15rem;
    display: flex;
    align-items: center;
    flex-flow: row;
}

.darts__menu h1 {
    margin: 0;
    flex-grow: 1;
}

.darts__menu button {
    background: linear-gradient(180deg, rgba(220,255,254,1) 0%, rgba(0,212,255,1) 100%);
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.darts__modal-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        rgba(0, 0, 0, 0.3), 
        rgba(0, 0, 0, 0.3)
    );
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0;
}

.darts__modal {
    max-width: 48rem;
    min-width: 16rem;
    background: white;
    -webkit-filter: brightness(100%);
    padding: 2rem;
    border-radius: 2rem;
    border: 4px ridge darkblue;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow-y: scroll;
    z-index: 300;
}

.darts__modal--x {
    position: absolute;
    right: 1rem;
    top: 1rem;
    text-decoration: none;
    background: none;
    border-radius: 50%;
    border: 1px solid black;
    padding: 0 0.25rem;
    cursor: pointer;
}

.darts__modal h2 {
    margin-top: 0;
}

.darts__modal--field {
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & span.darts__span {
        margin-bottom: 0.5rem;
        margin-left: 0.25rem;
    }
}

.darts__modal--field-tag {
    position: relative;
}

.darts__modal--field-x {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    border: solid black 1px;
    padding: 0 0.25rem;
    border-radius: 50%;
}

.darts__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.darts__item {
    background: white;
    width: 78px;
    height: 78px;
    border: 1px solid black;
    font-size: 0.75rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.darts__item:hover {
    -webkit-filter: brightness(70%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
}

.darts__item--shuffled {
    border: 4px solid yellow;
    width: 70px;
    height: 70px;
}

.darts__item--spacer {
    width: 78px;
    height: 78px;
    background: none;
}

.darts__item--1 {
    background: #000000;
    color: white;
}

.darts__item--2 {
    background: #00FFFF;
}

.darts__item--3 {
    background: #FFAC1C;
}

.darts__item--4 {
    background: #0C5395;
    color: white;
}

.darts__item--5 {
    background: #FFFF04;
}

.darts__item--5.darts__item--shuffled {
    border: 4px solid black;
}

.darts__item--6 {
    background: #50C878;
}

.darts__item--7 {
    background: #F891F8;
}

.darts__item--8 {
    background: #880808;
    color: white;
}

.darts__item--9 {
    background: #DCDCDC;
}
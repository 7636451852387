/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Gravity Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Regular'), url('Gravity-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Italic'), url('Gravity-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity UltraLight'), url('Gravity-UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity UltraLight Italic'), url('Gravity-UltraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Light'), url('Gravity-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Light Italic'), url('Gravity-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Book'), url('Gravity-Book.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Book Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Book Italic'), url('Gravity-BookItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Bold'), url('Gravity-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Gravity Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gravity Bold Italic'), url('Gravity-BoldItalic.woff') format('woff');
    }
.two-column {
    display: flex;
    flex-direction: column;
  }
  
  .two-column__left {
    display: inline-flex;
    justify-content: stretch;
    flex-direction: column;
    order: 1;
  }
  
  .two-column__left a:first-child {
    padding: 16px;
    margin-bottom: 16px;
    justify-self: center;
    text-align: center;
    border: 2px ridge black;
    background-color: #ff4e00;
    background-image: linear-gradient(315deg, #FDEE53 0%, #ec9f05 74%);
    font-size: 20px;
    border-radius: 16px;
  }
  
  .two-column__left a {
    text-decoration: none;
    border: 2px ridge black;
    border-bottom: none;
    padding: 8px 16px;
    color: black;
    text-align: center;
  }
  
  .two-column__left a:nth-child(2) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .two-column__left a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 2px ridge black;
  }
  
  .two-column__right {
    padding: 8px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    order: 0;
  }
  
  @media (min-width: 768px) {
    .two-column {
      flex-direction: row;
    }
  
    .two-column__left {
      width: 16rem;
    }
  
    .two-column__right {
      margin-left: 32px;
      order: 2;
    }
  }